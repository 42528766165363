import axios from 'axios'
import { ref } from 'vue'

const getTvShow = () => {
  const tvShow = ref({})
  const loading = ref(false)
  const scanning = ref(false)
  const loadTvShow = async id => {
    loading.value = true
    axios.get(`https://plex.nzbhorizon.com/library/metadata/${id}?includeConcerts=1&includeExtras=1&includeOnDeck=1&includePopularLeaves=1&includePreferences=1&includeStations=1&includeExternalMedia=1&X-Plex-Token=${process.env.VUE_APP_X_Plex_Token}`)
      .then(res => {
        console.log(res.data.MediaContainer.Metadata[0])
        res.data.MediaContainer.Metadata[0].X_Plex_Token = process.env.VUE_APP_X_Plex_Token
        tvShow.value = res.data.MediaContainer.Metadata[0]
        loading.value = false
      })
      .catch(err => {
        loading.value = false
      })
  }

  const scanTvShowDirectory = (path) => {
    scanning.value = true
    axios.get(`https://plex.nzbhorizon.com/library/sections/4/refresh?path=${path}&X-Plex-Token=${process.env.VUE_APP_X_Plex_Token}`)
      .then(res => {
        scanning.value = false
      })
      .catch(err => {
        loading.value = false
      })
  }
  return { tvShow, loading, loadTvShow, scanning, scanTvShowDirectory }
}

export default getTvShow
