<script>
  import getTvShow from "./composables/getTvShow";
  import { watch, onBeforeMount, ref } from "vue";
  import VLazyImage from "v-lazy-image"
  import formatDate from "../../composables/formatDate";
  import YoutubeVideoPlayer from "../YoutubeVideoPlayer.vue";
  import LoadingBar from "../LoadingBar.vue";
  import spinner from './components/spinner.vue'
  import MovieCasts from "./Cast.vue";
  
  export default {
    components: {
      YoutubeVideoPlayer,
      LoadingBar,
      VLazyImage,
      MovieCasts,
      spinner
    },
    props: ["id"],
    setup(props) {
      const { tvShow, movieVideo, loading, loadTvShow, scanning, scanTvShowDirectory } = getTvShow();
      const isOpenVideo = ref(false);

      onBeforeMount(() => {
        loadTvShow(props.id);
      });
      watch(() => props.id, (id) => {
        loadTvShow(id);
      })
      const openWindow = () => {
        let tvKey = tvShow.value.key
        tvKey = tvKey.substring(0, tvKey.lastIndexOf('/'))
        window.open(`https://plex.nzbhorizon.com/web/index.html#!/server/5fbf3a96180e5877fafa900b45e43e4825c92ac0/details?key=${tvKey}&X-Plex-Token=${process.env.VUE_APP_X_Plex_Token}`, '_blank')
      }
      const scanFolder = () => {
        let file = tvShow.value.Location[0].path
        scanTvShowDirectory(file)
      }
      return {
        tvShow,
        movieVideo,
        loading,
        isOpenVideo,
        formatDate,
        openWindow,
        scanFolder,
        scanning
      };
    },
  };
</script>

<template>
  <div v-if="loading"><loading-bar /></div>
  <div
    class="border-b border-gray-800 movie-info"
    @keydown.esc="isOpenVideo = false"
    v-if="!loading"
  >
    <div class="container flex flex-col px-4 py-16 mx-auto md:flex-row">
      <div class="flex-none">
        <v-lazy-image :src="`https://plex.nzbhorizon.com/photo/:/transcode?width=400&height=600&minSize=1&upscale=1&url=${tvShow.thumb}&X-Plex-Token=${tvShow.X_Plex_Token}`" src-placeholder="https://via.placeholder.com/400x600" class="transition ease-in-out duration-400 hover:opacity-80" alt="poster"/>
      </div>
      <div class="md:ml-24">
        <h2 class="mt-4 text-4xl font-semibold md:mt-0">
          {{ tvShow["title"] }}
        </h2>
        <div class="flex flex-wrap items-center mt-4 text-sm text-gray-700 dark:text-gray-400">
          <svg class="w-4 text-gray-700 fill-current dark:text-myyellow" viewBox="0 0 24 24">
            <g data-name="Layer 2">
              <path
                d="M17.56 21a1 1 0 01-.46-.11L12 18.22l-5.1 2.67a1 1 0 01-1.45-1.06l1-5.63-4.12-4a1 1 0 01-.25-1 1 1 0 01.81-.68l5.7-.83 2.51-5.13a1 1 0 011.8 0l2.54 5.12 5.7.83a1 1 0 01.81.68 1 1 0 01-.25 1l-4.12 4 1 5.63a1 1 0 01-.4 1 1 1 0 01-.62.18z"
                data-name="star"
              />
            </g>
          </svg>
          <span class="ml-1" v-if="tvShow['audienceRating']">{{ Math.round(tvShow["audienceRating"] * 10) }}%</span>
          <span class="ml-1" v-else-if="tvShow['rating']">{{ Math.round(tvShow["rating"] * 10) }}%</span>
          <span class="ml-1" v-else>N/A</span>
          <span class="mx-2">|</span>
          <span>{{ formatDate(tvShow["originallyAvailableAt"]) }}</span>
          <span class="mx-2">|</span>
          <span v-if="tvShow.Genre">{{ tvShow.Genre.map(a => a.tag).join(', ') }}</span>
        </div>

        <p class="mt-8 text-gray-700 dark:text-gray-300">{{ tvShow["summary"] }}</p>

        <div class="mt-12">
          <h4 class="font-semibold text-white">Directors</h4>
          <div class="flex mt-4">
            <div v-for="director in tvShow['Director']" :key="director.id" class="mr-6">
              <div>{{ director.tag }}</div>
            </div>
          </div>
        </div>

        <div class="mt-12" v-if="tvShow.key">
          <button
            @click="openWindow()"
            class="flex items-center px-5 py-4 font-semibold text-gray-900 transition duration-150 ease-in-out rounded bg-myyellow hover:opacity-70"
          >
            <svg class="w-6 fill-current" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
              />
            </svg>
            <span class="ml-2">Play Show</span>
          </button>
        </div>
        <div class="mt-3" v-if="tvShow.key">
          <button
            @click="scanFolder()"
            class="flex items-center px-5 py-4 font-semibold text-gray-900 transition duration-150 ease-in-out rounded bg-myyellow hover:opacity-70"
          >
            <span class="ml-2" v-if="scanning"><spinner/> Scanning...</span>
            <span class="ml-2" v-else>Scan Folder</span>
          </button>
        </div>
        <div class="mt-12" v-if="movieVideo">
          <button
            @click="isOpenVideo = true"
            class="flex items-center px-5 py-4 font-semibold text-gray-900 transition duration-150 ease-in-out rounded bg-myyellow hover:opacity-70"
          >
            <svg class="w-6 fill-current" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
              />
            </svg>
            <span class="ml-2">Play Trailer</span>
          </button>
          <div>
            <youtube-video-player
              @hideVideo="isOpenVideo = false"
              :videoKey="tvShow.videos.results[0].key"
              v-if="isOpenVideo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <movie-casts v-if="!loading" :movieCasts="tvShow.Role" />
</template>

<style>
</style>
